<template>
  <base-form-layout
    :title="`${action} ${$tc('lot.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemGroupLotEditForm
        ref="editForm"
        :item="itemGroupLot"
        :included-items="includedItems"
      />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemGroupLotEditForm from './components/ItemGroupLotEditForm'
import { ITEM_GROUP_LOT } from '@/models/item.group.lot'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'
export default {
  components: {
    ItemGroupLotEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    itemGroupLotId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemGroupLot: ITEM_GROUP_LOT(),
      includedItems: [],
      isLoading: false
    }
  },
  computed: {
    action() {
      return this.$t(this.itemGroupLotId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.itemGroupLotId) this.setup()
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      fetchItemGroupLot: 'itemGroupLot/fetch'
    }),

    async setup() {
      this.isLoading = true
      const { data, included } = await this.fetchItemGroupLot(
        this.itemGroupLotId
      )
      this.itemGroupLot = data
      this.includedItems = included
      this.isLoading = false
    },
    async onSubmit() {
      this.beforeSubmit = false
      this.isLoading = true
      try {
        const action = this.itemGroupLotId ? 'update' : 'create'
        const { data: itemGroupLot } = await this.$store.dispatch(
          `itemGroupLot/${action}`,
          this.itemGroupLot
        )
        await this.$refs.editForm.onSubmit(
          itemGroupLot.id,
          itemGroupLot.relationships.facility.data?.id
        )
        await this.createDocument(itemGroupLot.id, 'Item', 'Attachment')
        this.isLoading = false
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        this.isLoading = false
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
