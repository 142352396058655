<template>
  <div>
    <v-chip
      v-for="item in items"
      :key="item.id"
      v-bind="$attrs"
      class="mx-2"
      @click:close="remove(item.id)"
    >
      {{ item.attributes.description }} - {{ item.attributes.name }}
    </v-chip>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    async remove(id) {
      const item = this.items.find(item => item.id === id)
      const { data } = item.relationships.itemGroup
      if (!data) return
      item.attributes.itemGroupId = ''
      item.attributes.facilityId = ''
      await this.$store.dispatch('inventoryItem/update', item)
      this.$emit('click:close', id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
