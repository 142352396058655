<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    item-key="id"
    show-select
    loader-height="1"
    :items-per-page="15"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 50, -1]
    }"
    @input="$emit('input', selected)"
  >
    <template v-slot:[`item.attributes.name`]="{ item }">
      {{ item.attributes.name | titleCase }}
    </template>

    <template v-slot:[`item.customName`]="{ item }">
      {{ item.attributes.customAttributes.customName | titleCase }}
    </template>

    <template v-slot:[`item.specie`]="{ item }">
      {{
        item.attributes.customAttributes.specie &&
          $t(`species.${item.attributes.customAttributes.specie.text}`)
      }}
    </template>

    <template v-slot:[`item.dimensions`]="{ item }">
      <DimensionsTableField :item="item"
    /></template>
    <template v-slot:[`item.quantity`]="{ item }">
      <div v-if="item.attributes.status === 'in_stock'">
        {{ item.attributes.quantity }}
      </div>
      <v-chip v-else small outlined color=" 'error'">{{
        item.attributes.status | titleCase
      }}</v-chip>
    </template>

    <template v-slot:[`item.itemGroup`]="{ item }">
      <div
        v-for="name in getItemGroup(item.relationships.itemGroup.data)"
        :key="name"
      >
        {{ name }}
      </div>
    </template>
    <template v-slot:[`item.log`]="{ item }">
      {{ getItemResource(item.attributes.customAttributes.resource) || '-' }}
    </template>
    <template v-slot:[`item.cost`]="{ item }">
      {{ item.attributes.costPriceDetails.amount || '·' }}
      <span v-if="item.attributes.costPriceDetails.amount">{{
        item.attributes.costPriceDetails.currency
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
import DimensionsTableField from '@/components/DimensionsTableField'
export default {
  name: 'ItemFormTable',
  components: { DimensionsTableField },
  mixins: [UnitsConversion],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: [],
      isLoading: false,
      inventoryItemGroups: [],
      headers: [
        {
          text: this.$tc('item.name', 1) + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('item.description'),
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          sortable: false,
          align: 'end',
          value: 'dimensions'
        },

        {
          text: this.$tc('item.unit', 2),
          sortable: false,
          value: 'attributes.itemUnitsCount'
        },
        {
          text: this.$tc('lot.name', 2),
          sortable: false,
          value: 'itemGroup'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      facilities: 'facility/facilities'
    })
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      this.inventoryItemGroups = await this.fetchInventoryItemGroups()
      await this.fetchFacilities()
      this.isLoading = false
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async fetchInventoryItemGroups() {
      return await this.$store.dispatch('inventoryItemGroup/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    getFacility(facilityId) {
      if (!facilityId || !this.facilities.length) return
      const {
        attributes: { name }
      } = this.facilities.find(facility => facility.id === facilityId)
      return name
    },
    getItemGroup(data) {
      if (!data || !this.inventoryItemGroups.length) return
      const itemGroup = this.inventoryItemGroups.find(
        itemGroup => itemGroup.id === data.id
      )
      if (!itemGroup) return

      const { description } = itemGroup.attributes
      const facilityId = itemGroup.relationships.facility.data?.id
      const name = this.getFacility(facilityId)
      return { description, name }
    },
    getItemResource(id) {
      if (!id || !this.itemResources.length) return
      const response = this.itemResources.find(
        itemResource => itemResource.id === id
      )
      if (response) return response.attributes.description
    }
  }
}
</script>
