import { ORGANIZATION_ID } from '@/common/config.js'

export const ITEM_GROUP_LOT = () => ({
  id: '',
  type: 'itemGroup',
  attributes: {
    organizationId: ORGANIZATION_ID(),
    facilityId: '',
    clientId: '',
    name: 'lot',
    category: 'lot',
    description: '',
    customAttributes: {
      yield: null,
      profit: null,
      incomingShipmentIds: []
    }
  }
})
