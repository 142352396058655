<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>
    <v-row dense
      ><v-col cols="12" md="6"
        ><base-field-input
          v-model="item.attributes.description"
          :label="$tc('lot.name', 1) + '#'"
      /></v-col>

      <v-col cols="12" md="6">
        <base-selector
          v-model="item.attributes.facilityId"
          :items="facilities"
          :label="$t('facility.location')"
          item-text="attributes.name"
          item-value="id"
      /></v-col>
      <v-col cols="12" md="6">
        <base-autocomplete
          :label="`${$t('selectors.select')} ${$tc('purchase.name', 1)}`"
          :items="purchaseOrders"
          item-text="attributes.customAttributes.name"
          item-value="id"
          @input="onInput"
      /></v-col>
    </v-row>

    <ItemGroupLotEditFormSelectedItems
      :items="selectedItems"
      :close="!!item.id"
      class="my-4"
      @click:close="removeItem"
    />

    <ItemFormTable
      v-if="itemResources.length"
      :items="itemResources"
      @input="updateSelectedItems"
    />
    <v-alert v-else-if="!item.id" type="info" text>{{
      $t('alerts.selectIncomingShipment')
    }}</v-alert>

    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config'
import ItemFormTable from './ItemFormTable'
import ItemGroupLotEditFormSelectedItems from './ItemGroupLotEditFormSelectedItems'
export default {
  name: 'ItemGroupLotEditForm',
  components: {
    ItemFormTable,
    ItemGroupLotEditFormSelectedItems
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    includedItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      purchaseOrders: [],
      isLoading: false,
      itemResources: [],
      selectedItems: [],
      facilities: []
    }
  },
  watch: {
    includedItems(value) {
      if (value.length) this.selectedItems = value
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      this.purchaseOrders = await this.filterPurchaseOrders()
      this.facilities = await this.fetchFacilities()
      this.isLoading = false
    },
    async onInput(orderId) {
      if (!orderId) return (this.itemResources = [])
      this.itemResources = await this.filterItemsByItemQuotation(orderId)
    },
    async filterPurchaseOrders() {
      return await this.$store.dispatch('purchaseOrder/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async filterItemsByItemQuotation(orderId) {
      const response = await this.$store.dispatch('itemQuotation/filter', {
        orderId
      })
      return await this.$store.dispatch('itemResource/filter', {
        parentId: response[0].id
      })
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    updateSelectedItems(items) {
      const reducer = []
      this.selectedItems = reducer.concat(items)
    },
    removeItem(id) {
      const index = this.selectedItems.findIndex(item => item.id === id)
      this.selectedItems.splice(index, 1)
    },
    async onSubmit(itemGroupId, facilityId) {
      for await (const item of this.selectedItems) {
        Object.assign(item.attributes, { itemGroupId, facilityId })
        await this.$store.dispatch('inventoryItem/update', item)
      }
    }
  }
}
</script>
